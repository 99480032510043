export function getBaseUrl(): string {
  return window.location.origin;
}

let config = {
  apiUrl: getBaseUrl()
};

if (config.apiUrl.startsWith("__BAGET_PLACEHOLDER_")) {
  config.apiUrl = "";
}

if (config.apiUrl.endsWith("/")) {
  config.apiUrl = config.apiUrl.slice(0, -1);
}

export { config };
